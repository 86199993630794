.studentsDropdown {
  background: #ffffff;
}
.studentsDropdown:hover {
  pointer: cursor;
}

.searchContainer {
  align-items: start;
}

.error {
  color: red;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
