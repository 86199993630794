.buttonHidden {
  z-index: 100;
  opacity: 0;
  position: absolute;
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
}
