.containerButton {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 0 0 7px;
  transition: all 0.2s ease;

  &.paused {
    background-color: #dc3545;
  }
  &.paused:hover {
    background-color: #c82333;
    border: 1px solid #bd2130;
  }
}

.containerButton:hover {
  background-color: #218838;
  border: 1px solid #1e7e34;
}

.buttonPlayPause {
  box-sizing: border-box;
  width: 0;
  height: 20px;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #202020;
  cursor: pointer;
  will-change: border-width;
  transition: all 0.2s ease;
  margin-left: 4px;

  &.paused {
    border-style: double;
    border-width: 0px 0 0px 15px;
    margin: 2px 0 0 2px;
  }
  &:hover {
    border-color: transparent transparent transparent #404040;
  }
}
