.filterPlan {
  width: 180px;
  border-radius: 10px;
}

.filterPlanControl {
  color: #ffffff !important;
}

.bg-light {
  background-color: #ffffff;
  color: #000000;
}
