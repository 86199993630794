.customSelect {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23495057' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.customSelect:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.customSelect:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.customSelect[multiple],
.customSelect[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.customSelect:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.customSelect::-ms-expand {
  opacity: 0;
}

.customSelect-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 75%;
}

.customSelect-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 125%;
}
