body {
  background: linear-gradient(to right, #e9e4f0, #d3cce3);
}
.pdfToMail {
  div {
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 12px;
  }

  @font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
  }
  @font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
  }
  @font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
  }
}

/* Sidebar configurator */
.backdrop {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(8, 8, 8, 0.7);
}
.sidebar {
  top: 0;
  height: 100vh;
  width: 32vw; /* padding-top: 120px !important; */
}
.birthday-button {
  padding: 15px 5px;
  top: 85vh;
  border-radius: 0 10px 10px 0;
}
.birthday-button,
.sidebar {
  transition: 0.3s;
  color: #efefef;
  background: rgba(8, 8, 8, 0.9);
  box-shadow: 10px 0px 34px -19px rgba(0, 0, 0, 1);
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  border: none;
  position: absolute;
  z-index: 11;
}
.modal-90w {
  width: 1280px;
}
main {
  overflow: hidden;
}

.small-display {
  font-size: 20px;
}
.multiline {
  white-space: pre-wrap;
}
.bg-transdark {
  background: rgba(0, 0, 0, 0.5);
}
.bg-transred {
  background: rgba(255, 0, 0, 1);
}
.small-boi {
  font-size: 12px;
}

.l-bigger {
  font-size: 24px;
}

.lead {
  font-size: 20px !important;
}
.alert-fixed {
  z-index: 99999 !important;
  position: fixed;
  text-align: center;
  top: 4rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
}
.lower-buttons {
  position: fixed;
  width: auto;
  top: 92%; /* left:80%; */
  transform: translate(-50%, -50%);
  transition: 0.8s;
}
.text-darkYell {
  color: #c6a15b !important;
}
.border-gray {
  border: 1px solid #bfbfbf;
}
code {
  font-family: "Monaco", "Consolas", monospace;
  white-space: pre-wrap;
  word-break: break-all;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  padding: 3px;
  border-radius: 3px;
  line-height: 1;
  height: 100%;
}
.verticalText {
  transform: rotate(-90deg);
  margin-top: 300px;
}
/* RollingCode School Logo - Loader */
.spinner {
  width: 4rem;
  height: 4rem;
  border: 3px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.5);
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  animation: rotation 0.8s ease infinite;
}
.rclogo-center {
  position: relative;
  top: -53px;
  left: 12px;
  font-size: 24px;
  font-weight: bolder;
  color: gray;
}
.ls-row {
  height: 65vh;
}
.ls-div {
  width: 4rem;
  height: 4rem;
}
.d-block-artificial {
  display: block;
}
.d-none-artificial {
  display: none;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* The examples load the DejaVu Sans from the Kendo UI CDN. Other fonts have to be hosted from your application.
The official site of the Deja Vu Fonts project is https://dejavu-fonts.github.io/. */
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

.border-rounded {
  border-radius: 200px 200px 200px 200px !important;
  -moz-border-radius: 200px 200px 200px 200px !important;
  -webkit-border-radius: 200px 200px 200px 200px !important;
  border: 0px solid #000000 !important;
}

.campus-button {
  margin-top: 15px;
}

.coffee-signal-button {
  background: transparent !important;
  border: none !important;
  color: sandybrown !important;
  float: right;
  font-size: 22px !important;
}

.coffee-signal-button:focus {
  box-shadow: none !important;
}

.lightBulbOff {
  opacity: 0.2;
  color: red;
}
